export enum Path {
   access = '/access',
   admin = '/admin',
   adminPermissions = '/admin/permissions',
   adminPermission = '/admin/permissions/:permissionId',
   adminRoles = '/admin/roles',
   adminRole = '/admin/roles/:roleId',
   adminUsers = '/admin/users',
   adminUser = '/admin/users/:userId',
   campaign = '/campaigns/:campaignId',
   campaigns = '/campaigns',
   experiments = '/experiments',
   experiment = '/experiments/:experimentId',
   home = '/',
   message = '/messages/:messageId',
   profile = '/profile',
   releaseNotes = '/release-notes',
   unauthorized = '/unauthorized',
}