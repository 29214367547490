export enum Endpoint {
   audience = '/SelectedPersonPool/ref_experiment_id',
   audienceCount = '/SelectedPersonPool/count',
   audienceMember = '/Recruitment/spp_person_id',
   cohort = '/ExperimentFilter',
   emailTemplate = '/RefModeEmail',
   emailTemplateTag = '/ModeEmailTag',
   experiment = '/RefExperiment',
   experimentTag = '/RefExperimentTag',
   experimentTagRelationship = '/ExperimentTag',
   hypothesis = '/ExperimentHypothesis',
   inAppMessageTemplate = '/RefModeInAppMessage',
   inAppMessageTemplateTag = '/ModeInAppMessageTag',
   messageTag = '/RefModeTag',
   permission = '/RefAppAccess',
   permissionEntity = '/RefAppAccess/ref_app_access_id',
   pimVerify = '/PIM/verify',
   role = '/RefRole',
   rolePermission = '/RoleAppAccess',
   sentEmail = '/InterventionEmail',
   sentInAppMessage = '/SBInterventionInAppMessage',
   sentTextMessage = '/SBInterventionText',
   textTemplate = '/RefModeText',
   textTemplateTag = '/ModeTextTag',
   updateAudience = '/SelectedPersonPool/filtered/ref_experiment_id',
   user = '/User',
   userDemographic = '/UserDemographic',
   userRole = '/UserRole',
}