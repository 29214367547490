import type { GenericObject } from '../../common/types/GenericObject';

export const translations: GenericObject = Object.freeze({
   en: {},
   es: {
      translation: {
         'A Permission with this name already exists!': '¡Ya existe un Permiso con este nombre!',
         'A Role with this name already exists!': '¡Ya existe un Rol con este nombre!',
         'Accessible with these Permissions': 'Accesible con estos Permisos',
         'Add Cohort': 'Agregar Cohorte',
         'Add Message': 'Agregar Mensaje',
         'Add Message Template': 'Agregar Plantilla de Mensaje',
         'Add HTML Body': 'Agregar Cuerpo HTML',
         'Add Subject': 'Añadir Tema',
         'Add Text Body': 'Agregar Cuerpo de Texto',
         'Add experiment tags': 'Agregar etiquetas de experimento',
         'Add message tags': 'Agregar etiquetas de mensaje',
         'Add permission to this role': 'Agregar permiso a este rol',
         'Add permissions to this role': 'Agregar permisos a este rol',
         'Add user to this role': 'Agregar usuario a este rol',
         'Add users to this role': 'Agregar usuarios a este rol',
         'Admin.Read': 'Administrador.Leer',
         'Admin.Write': 'Administrador.Escribir',
         'All messages have been sent and recipient activities are being observed.': 'Se han enviado todos los mensajes y se están observando las actividades de los destinatarios.',
         'An Experiment with this name already exists!': '¡Ya existe un Experimento con este nombre!',
         'An error occurred.': 'Ocurrió un error.',
         'Approver.Read': 'Aprobador.Leer',
         'Approver.Write': 'Aprobador.Escribir',
         'Approximate Audience Size': 'Tamaño Aproximado de la Audiencia',
         'Are you sure?': '¿Está seguro?',
         'Audience Table': 'Tabla de Audiencia',
         'Blank Template': 'Plantilla en Blanco',
         'Campaign Tabs': 'Pestañas de Campaña',
         'Clone this Campaign': 'Clonar esta Campaña',
         'Clone this Experiment': 'Clonar este Experimento',
         'Cohort Table': 'Tabla de Cohortes',
         'Column title': 'Título de la columna',
         'Community User Logins': 'Inicios de Sesión de Usuarios de la Comunidad',
         'Create new Campaign': 'Crear nueva Campaña',
         'Create new Experiment tag': 'Crear nueva etiqueta de Experimento',
         'Create new Experiment': 'Crear nuevo Experimento',
         'Create new Hypothesis': 'Crear nueva Hipótesis',
         'Create new Message tag': 'Crear nueva etiqueta de Mensaje',
         'Create new Permission': 'Crear nuevo Permiso',
         'Create new Role': 'Crear nuevo Rol',
         'Current Local Time': 'Hora Local Actual',
         'Current UTC Time': 'Hora UTC Actual',
         'Current Version': 'Versión Actual',
         'Display Name': 'Nombre para mostrar',
         'Edit Experiment': 'Editar Experimento',
         'Edit Permission': 'Editar Permiso',
         'Edit Role': 'Editar Rol',
         'Edit User': 'Editar Usuario',
         'Email Table': 'Tabla de Correo Electrónico',
         'Email Template': 'Plantilla de Correo Electrónico',
         'Email Template Menu': 'Menú de Plantilla de Correo Electrónico',
         'Email Templates': 'Plantillas de Correo Electrónico',
         'Ends On': 'Termina En',
         'Experiment Tabs': 'Pestañas de Experimentos',
         'Experiment Tag Name': 'Nombre de la Etiqueta del Experimento',
         'Experiment Tags': 'Etiquetas de Experimento',
         'Filter by Message Tag Menu': 'Filtrar por Menú de Etiqueta de Mensaje',
         'Filter by Message Tag': 'Filtrar por Etiqueta de Mensaje',
         'Filter by Template Name': 'Filtrar por Nombre de Plantilla',
         'Find column': 'Buscar columna',
         'First Name': 'Nombre de Pila',
         'Full Name': 'Nombre Completo',
         'Help on Audiences': 'Ayuda Sobre Audiencias',
         'Help on Body Fields': 'Ayuda en los Campos Corporales',
         'Help on End Dates': 'Ayuda Sobre Fechas de Finalización',
         'Help on Sent Messages': 'Ayuda Sobre Mensajes Enviados',
         'Help on Send-From Values': 'Ayuda Sobre Valores de Envío Desde',
         'Help on Stages': 'Ayuda en Etapas',
         'Help on UTC': 'Ayuda en UTC',
         'Help on Weights': 'Ayuda con Pesas',
         'HTML Body': 'Cuerpo HTML',
         'Hide all': 'Ocultar todo',
         'Hide column': 'Ocultar columna',
         'In-App Message': 'Mensaje en la Aplicación',
         'In-App Message Table': 'Tabla de Mensajes en la Aplicación',
         'In-App Message Template': 'Plantilla de Mensajes en la Aplicación',
         'In-App Message Template Menu': 'Menú de Plantilla de Mensajes en la Aplicación',
         'In-App Message Templates': 'Plantillas de Mensajes en la Aplicación',
         'In-App Messages': 'Mensajes en la Aplicación',
         'Info Menu': 'Menú de Información',
         'Language Menu': 'Menú Idioma',
         'Last Login Time': 'Hora del Último Inicio de Sesión',
         'Last Name': 'Apellido',
         'Manage columns': 'Administrar columnas',
         'Message Tag Name': 'Nombre de la Etiqueta del Mensaje',
         'Message Tags': 'Etiquetas de Mensajes',
         'Message Type': 'Tipo de Mensaje',
         'Middle Name': 'Segundo Nombre',
         'New Campaign': 'Nueva Campaña',
         'New Experiment': 'Nuevo Experimento',
         'New Permission': 'Nuevo Permiso',
         'New Role': 'Nuevo Rol',
         'No messages have been scheduled for this campaign.': 'No se han programado mensajes para esta campaña.',
         'No messages have been scheduled for this experiment.': 'Este experimento se está ejecutando actualmente.',
         'No messages were sent for this experiment because it contained no message templates.': 'No se enviaron mensajes para este experimento porque no contenía plantillas de mensajes.',
         'Page not found': 'Página no encontrada',
         'Recipient ID': 'ID del Destinatario',
         'Ref User ID': 'ID de Usuario de Referencia',
         'Related permissions': 'Permisos relacionados',
         'Related roles': 'Roles relacionadas',
         'Related users': 'Usuarios relacionados',
         'Release Notes Table': 'Tabla de Notas de la Versión',
         'Release Notes': 'Notas de la Versión',
         'Remove permission from this role': 'Quitar el permiso de este rol',
         'Remove tag from this experiment': 'Eliminar etiqueta de este experimento',
         'Remove tag from this message template': 'Eliminar etiqueta de esta plantilla de mensaje',
         'Remove user from this role': 'Eliminar usuario de este rol',
         'Rows per page': 'Filas por página',
         'Scientist.Read': 'Científico.Leer',
         'Scientist.Write': 'Científico.Escribir',
         'Select Access': 'Seleccione Acceso',
         'Select Base Template': 'Seleccionar Plantilla Base',
         'Select Column': 'Seleccionar Columna',
         'Select Condition': 'Seleccionar Condición',
         'Select Goal': 'Seleccionar Objetivo',
         'Select Logout': 'Seleccione Cerrar Sesión',
         'Select Message Type': 'Seleccionar Tipo de Mensaje',
         'Select Owner': 'Seleccionar Propietario',
         'Select Profile': 'Seleccionar Perfil',
         'Select Release Notes': 'Seleccionar Notas de la Versión',
         'Select Table': 'Seleccionar Tabla',
         'Send Messages From': 'Enviar Mensajes Desde',
         'Send On': 'Mandar En',
         'Sent On': 'Enviado',
         'Session Menu': 'Menú de Sesión',
         'Show all': 'Mostrar todo',
         'Sort by ASC': 'Ordenar por ASC',
         'Sort by DESC': 'Ordenar por DESC',
         'Start Date': 'Fecha de Inicio',
         'Template ID': 'ID de plantilla',
         'Text Body': 'Cuerpo de Texto',
         'Text Table': 'Tabla de Texto',
         'Text Template Menu': 'Menú de Plantilla de Texto',
         'Text Template': 'Plantilla de Texto',
         'Text Templates': 'Plantillas de Texto',
         'The campaign has been deleted.': 'La campaña ha sido eliminada.',
         'The campaign has been updated.': 'La campaña ha sido actualizada.',
         'The cohort has been added.': 'Se ha agregado la cohorte.',
         'The cohort has been deleted.': 'La cohorte ha sido eliminada.',
         'The experiment has been added.': 'Se ha añadido el experimento.',
         'The experiment has been deleted.': 'El experimento ha sido eliminado.',
         'The experiment has been updated.': 'El experimento ha sido actualizado.',
         'The experiment tag has been added.': 'Se ha añadido la etiqueta del experimento.',
         'The experiment tag has been removed.': 'Se eliminó la etiqueta del experimento.',
         'The hypothesis has been created.': 'La hipótesis ha sido creada.',
         'The hypothesis has been deleted.': 'La hipótesis ha sido eliminada.',
         'The hypothesis has been updated.': 'La hipótesis ha sido actualizada.',
         'The message tag has been added.': 'Se ha añadido la etiqueta del mensaje.',
         'The message tag has been removed.': 'La etiqueta del mensaje ha sido eliminada.',
         'The message template has been added.': 'Se ha agregado la plantilla de mensaje.',
         'The message template has been deleted.': 'La plantilla de mensaje ha sido eliminada.',
         'The message template has been updated.': 'La plantilla de mensaje ha sido actualizada.',
         'The permission has been added.': 'El permiso ha sido agregado.',
         'The permission has been deleted.': 'El permiso ha sido eliminado.',
         'The permission has been removed.': 'El permiso ha sido eliminado.',
         'The permission has been updated.': 'El permiso ha sido actualizado.',
         'The role has been added.': 'Se ha añadido el rol.',
         'The role has been deleted.': 'El rol ha sido eliminado.',
         'The role has been updated.': 'El rol ha sido actualizado.',
         'The user has been added.': 'El usuario ha sido añadido.',
         'The user has been removed.': 'El usuario ha sido eliminado.',
         'The user has been updated.': 'El usuario ha sido actualizado.',
         'There are no messages scheduled to be sent for this experiment.  To schedule message sends, configure one-or-more templates on the TEMPLATES tab.': 'No hay mensajes programados para enviarse para este experimento. Para programar el envío de mensajes, configure una o más plantillas en la pestaña PLANTILLAS.',
         'This campaign has concluded.': 'Esta campaña ha concluido.',
         'This campaign has messages that are scheduled to be sent.': 'Esta campaña tiene mensajes que están programados para ser enviados.',
         'This campaign is in the process of sending messages.': 'Esta campaña está en proceso de envío de mensajes.',
         'This experiment has concluded.': 'Este experimento ha concluido.',
         'This experiment has messages that are scheduled to be sent.': 'Este experimento tiene mensajes que están programados para enviarse.',
         'This experiment is in the process of sending messages.': 'Este experimento está en proceso de envío de mensajes.',
         'This experiment occurs in the future': 'Este experimento ocurre en el futuro',
         'This is for the unit tests.': 'Esto es para las pruebas unitarias.',
         'Unauthorized Access': 'Acceso No Autorizado',
         'User logs into Community': 'El usuario inicia sesión en la Comunidad',
         'Users assigned to this role': 'Usuarios asignados a este rol',
         'Users with this role have these permissions': 'Los usuarios con este rol tienen estos permisos',
         'Your Roles & Permissions': 'Sus Roles y Permisos',
         'Zip Code': 'Código Postal',
         'active permission is related to this role.': 'permiso activo está relacionado con este rol.',
         'active permissions are related to this role.': 'permisos activos están relacionados con este rol.',
         'active role is related to this permission.': 'rol activo está relacionado con este permiso.',
         'active role is related to this user.': 'rol activo está relacionado con este usuario.',
         'active roles are related to this permission.': 'roles activos están relacionados con este permiso.',
         'active roles are related to this user.': 'roles activos están relacionados con este usuario.',
         'active user is related to this permission.': 'usuario activo está relacionado con este permiso.',
         'active user is related to this role.': 'usuario activo está relacionado con este rol.',
         'active users are related to this permission.': 'usuarios activos están relacionados con este permiso.',
         'active users are related to this role.': 'usuarios activos están relacionados con este rol.',
         'does not equal': 'no es igual',
         'is after': 'es despues',
         'is before': 'es antes',
         'is greater than': 'es mayor que',
         'is greater than or equal to': 'es mayor o igual a',
         'is less than': 'es menos que',
         'is less than or equal to': 'es menor o igual a',
         'of more than': 'de mas de',
         Access: 'Acceso',
         Add: 'Agregar',
         Address: 'Dirección',
         Active: 'Activo',
         Admin: 'Administrador',
         AND: 'Y',
         Approver: 'Aprobador',
         Audience: 'Audiencia',
         Begins: 'Comenzar',
         Campaign: 'Campaña',
         Campaigns: 'Campañas',
         Cancel: 'Cancelar',
         City: 'Ciudad',
         Clear: 'Despejar',
         Close: 'Cerrarse',
         Cohorts: 'Cohortes',
         Column: 'Columna',
         Concluded: 'Concluido',
         Condition: 'Condición',
         Create: 'Crear',
         Date: 'Fecha',
         Delete: 'Borrar',
         Description: 'Descripción',
         Detail: 'Detalle',
         Details: 'Detalles',
         Disabled: 'Deshabilitado',
         Dismiss: 'Despedir',
         Edit: 'Editar',
         End: 'Fin',
         Email: 'Correo Electrónico',
         Emails: 'Correos Electrónico',
         Experiment: 'Experimento',
         Experiments: 'Experimentos',
         Filter: 'Filtrar',
         First: 'Primero',
         Gender: 'Género',
         Goal: 'Objetivo',
         Home: 'Base',
         Hypotheses: 'Hipótesis',
         Hypothesis: 'Hipótesis',
         ID: 'ID',
         Info: 'Información',
         Language: 'Idioma',
         Last: 'Último',
         Layouts: 'Diseños',
         Logout: 'Cerrar Sesión',
         Menu: 'Menú',
         Message: 'Mensaje',
         Messages: 'Mensajes',
         Middle: 'Medio',
         Name: 'Nombre',
         Notes: 'Notas',
         Observing: 'Observando',
         Owner: 'Propietario',
         Paused: 'En Pausa',
         People: 'Gente',
         Permission: 'Permiso',
         Permissions: 'Permisos',
         Phone: 'Teléfono',
         Profile: 'Perfil',
         Read: 'Leer',
         Reader: 'Lector',
         Recipient: 'Destinatario',
         Relationships: 'Relaciones',
         Remove: 'Eliminar',
         Reset: 'Reiniciar',
         Return: 'Devolver',
         Reviews: 'Reseñas',
         Role: 'Rol',
         Roles: 'Roles',
         Save: 'Ahorrar',
         Schedule: 'Cronograma',
         Scheduled: 'Programado',
         Scientist: 'Científico',
         Select: 'Seleccionar',
         Sending: 'Enviando',
         Session: 'Sesión',
         Sort: 'Clasificar',
         State: 'Estado',
         Subject: 'Tema',
         Switchboard: 'Tablero de Conmutadores',
         Table: 'Tabla',
         Tags: 'Etiquetas',
         Template: 'Plantilla',
         Templates: 'Plantillas',
         Text: 'Texto',
         Texts: 'Texts',
         Unscheduled: 'No Programado',
         User: 'Usuario',
         Users: 'Usuarios',
         UTC: 'UTC',
         Value: 'Valor',
         Variables: 'Variables',
         Version: 'Versión',
         View: 'Mirar',
         Viewer: 'Espectadora',
         Weight: 'Peso',
         Write: 'Escribir',
         equals: 'es igual',
         of: 'de',
      },
   },
})