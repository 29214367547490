export enum SessionItem {
   campaignTagRelationships = 'campaignTagRelationships',
   campaignTags = 'campaignTags',
   campaigns = 'campaigns',
   cohorts = 'cohorts',
   emailTemplateTags = 'emailTemplateTags',
   emailTemplates = 'emailTemplates',
   emails = 'emails',
   experimentTagRelationships = 'experimentTagRelationships',
   experimentTags = 'experimentTags',
   experiments = 'experiments',
   hypotheses = 'hypotheses',
   inAppMessageTemplateTags = 'inAppMessageTemplateTags',
   inAppMessageTemplates = 'inAppMessageTemplates',
   inAppMessages = 'inAppMessages',
   loginTime = 'loginTime',
   messageTags = 'messageTags',
   permissions = 'permissions',
   rolePermissions = 'rolePermissions',
   roles = 'roles',
   textTemplateTags = 'textTemplateTags',
   textTemplates = 'textTemplates',
   texts = 'texts',
   user = 'user',
   userRoles = 'userRoles',
   users = 'users',
}